<template>
    <div>
        <div class="row q-col-gutter-sm">
            <!-- NO IDENTIFICACION -->
            <div class="col-6">
                <q-input outlined v-model="retencion.impLocRetenido" label="Imp. local retenido" dense />
            </div>
            <!-- TASA DE RETENCION -->
            <div class="col-12 col-md-3">
                <q-field v-model="retencion.tasadeRetencion" label="Tasa de retención" dense outlined>
                    <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                        <vue-number :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                            v-show="floatingLabel" v-bind="importeFormat"></vue-number>
                    </template>
                </q-field>
            </div>
            <!-- DESCUENTO -->
            <div class="col-12 col-md-3">
                <q-field v-model="retencion.importe" label="Importe" dense outlined>
                    <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                        <vue-number :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                            v-show="floatingLabel" v-bind="importeFormat"></vue-number>
                    </template>
                </q-field>
            </div>
            <div class="col-12">
                <q-btn label="Agregar" color="primary" class="full-width" @click="PushRetencion()"></q-btn>
            </div>
        </div>
        <!-- TABLA DE RETENCIONES -->
        <q-table title="Retenciones" :data="impLocal.retencionesLocales" :columns="columnsRetenciones" row-key="item"
            :rows-per-page-options="[10]" class="my-sticky-column-table">
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td auto-width key="acciones">
                        <q-btn size="md" color="red" rounded flat dense @click="DeleteRetencion(props.row)"
                            icon="mdi-delete">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px" :offset="[10, 10]">Eliminar retencion</q-tooltip>
                        </q-btn>
                    </q-td>
                    <q-td key="impLocRetenido" :props="props">{{ props.row.impLocRetenido }}</q-td>
                    <q-td key="tasadeRetencion" :props="props">{{ props.row.tasadeRetencion }}</q-td>
                    <q-td key="importe" :props="props">{{ props.row.importe }}</q-td>
                </q-tr>
            </template>
        </q-table>
    </div>
</template>
<script>
import axios from 'axios'
import { QSpinnerCube } from 'quasar';
import { component as VueNumber } from '@coders-tm/vue-number-format'

export default {
    components: {
        VueNumber
    },
    data() {
        return {
            //RETENCIONES
            columnsRetenciones: [
                { name: 'actions', align: 'left', label: 'Acciones', field: 'actions' },
                { name: 'impLocRetenido', align: 'left', label: 'Imp. loc. retenido', field: 'impLocRetenido' },
                { name: 'tasadeRetencion', align: 'left', label: 'Tasa de retención', field: 'tasadeRetencion' },
                { name: 'importe', align: 'left', label: 'Importe', field: 'importe' },
            ],
            retencion: {
                impLocRetenido: '',
                tasadeRetencion: 0,
                importe: 0,
            },

            //TRASLADOS
            columnsTrasladados: [
                { name: 'actions', align: 'left', label: 'Acciones', field: 'actions' },
                { name: 'impLocTrasladado', align: 'left', label: 'Imp. loc. trasladado', field: 'impLocTrasladado' },
                { name: 'tasadeTraslado', align: 'left', label: 'Tasa de traslado', field: 'tasadeTraslado' },
                { name: 'importe', align: 'left', label: 'Importe', field: 'importe' },
            ],
            trasladado: {
                impLocRetenido: '',
                tasadeRetencion: 0,
                importe: 0,
            },

            //FORMATO NUMERICO
            importeFormat: {
                decimal: '.',
                separator: ',',
                prefix: '',
                precision: 2,
                masked: false,
                minimumFractionDigits: 2,
            },
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        impLocal() {
            return this.$store.state.comprobanteStore.comprobante.impLocal;
        },

    },
    created() {

    },
    methods: {
        PushRetencion() {
            const ret = { ...this.retencion };
            if (ret.impLocRetenido === "") {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique el nombre de la retención.`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }

            if (ret.tasadeRetencion <= 0 || ret.tasadeRetencion === "") {
                this.$q.notify({
                    type: 'warning',
                    message: `La base de la retención debe ser mayor a 0.`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }

            if (ret.importe <= 0 || ret.importe === "") {
                this.$q.notify({
                    type: 'warning',
                    message: `La tasa de la retención debe ser mayor a 0`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }

            const def = {
                impLocRetenido: '',
                tasadeRetencion: 0,
                importe: 0,
            };
            this.$store.state.comprobanteStore.comprobante.impLocal.retencionesLocales.push(ret)
            this.retencion = { ...def };
            this.CalculaTotales();
            console.log(this.$store.state.comprobanteStore.comprobante.impLocal);
        },

        DeleteRetencion(item) {
            let indice = this.$store.state.comprobanteStore.comprobante.impLocal.retencionesLocales.indexOf(item);
            this.$store.state.comprobanteStore.comprobante.impLocal.retencionesLocales.splice(indice, 1);
            this.CalculaTotales();
            console.log(this.$store.state.comprobanteStore.comprobante.impLocal);
        },

        CalculaTotales() {
            const totalImpuestosRetenidos = this.$store.state.comprobanteStore.comprobante.impuestos.totalImpuestosRetenidos;
            const totalImpuestosTrasladados = this.$store.state.comprobanteStore.comprobante.impuestos.totalImpuestosTrasladados;
            const totaldeRetenciones = this.$store.state.comprobanteStore.comprobante.impLocal.retencionesLocales.reduce((acumulador, objeto) => acumulador + parseFloat(objeto.importe), 0);
            const totaldeTraslados = this.$store.state.comprobanteStore.comprobante.impLocal.trasladosLocales.reduce((acumulador, objeto) => acumulador + parseFloat(objeto.importe), 0);
            const total = this.$store.state.comprobanteStore.comprobante.subTotal - totaldeRetenciones + totaldeTraslados - totalImpuestosRetenidos + totalImpuestosTrasladados;
            this.$store.state.comprobanteStore.comprobante.impLocal.totaldeRetenciones = totaldeRetenciones;
            this.$store.state.comprobanteStore.comprobante.impLocal.totaldeTraslados = totaldeTraslados;
            this.$store.state.comprobanteStore.comprobante.total = total;
        },
    },
}
</script>