<template>
    <div>

        <!-- NUEVO ARTICULO -->
        <q-dialog v-model="dialogNuevoArticulo" width="800px" persistent transition-show="scale"
            transition-hide="scale">
            <Articulos @cierraVentana="closeDialogArticulo()"></Articulos>
        </q-dialog>

        <!-- LISTA DE ARTICULOS -->
        <q-table title="Artículos" :data="itemsArticulos" :loading="loadingTabla" :columns="columns"
            row-key="noIdentificacion" :filter="filter">
            <template v-slot:top-right>
                <q-input class="q-mr-lg" filled dense debounce="300" v-model="filter" placeholder="Filtrar">
                    <template v-slot:append>
                        <q-icon name="search" />
                    </template>
                </q-input>
                <q-btn round color="green" icon="mdi-plus" @click="nuevoArticulo()" class="q-mr-sm">
                    <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                        :offset="[10, 10]">Nuevo Artículo</q-tooltip>
                </q-btn>
                <q-btn round color="blue" icon="mdi-magnify" @click="getArticulo()" class="q-mr-sm">
                    <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                        :offset="[10, 10]">Consultar Artículos</q-tooltip>
                </q-btn>
            </template>

            <template v-slot:loading>
                <q-inner-loading showing color="primary" />
            </template>

            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td key="noIdentificacion" :props="props">{{ props.row.noIdentificacion }}</q-td>
                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                    <q-td key="claveProdServ" :props="props">{{ props.row.claveProdServ.claveProdServ }}</q-td>
                    <q-td key="claveUnidad" :props="props">{{ props.row.claveUnidad.claveUnidad }}</q-td>
                    <q-td key="unidad" :props="props">{{ props.row.unidad }}</q-td>
                    <q-td auto-width>
                        <q-btn size="md" color="blue" rounded flat dense @click="editarArticulo(props.row)"
                            icon="mdi-pencil">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px">Editar</q-tooltip>
                        </q-btn>
                        <q-btn size="md" color="red" rounded flat dense @click="eliminarArticulo(props.row)"
                            icon="mdi-delete">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px">Eliminar</q-tooltip>
                        </q-btn>
                    </q-td>
                </q-tr>
            </template>
        </q-table>
    </div>
</template>
<script>
import axios from 'axios';
import { QSpinnerCube } from 'quasar';
import Articulos from './Articulos.vue';

export default {
    components: { Articulos },
    data() {
        return {
            columns: [
                { name: 'noIdentificacion', align: 'center', label: 'No. Identificación', field: 'noIdentificacion', sortable: true, classes: 'bg-grey-2 ellipsis', headerClasses: 'bg-primary text-white' },
                { name: 'descripcion', align: 'center', label: 'Descripción', field: 'descripcion', sortable: true },
                { name: 'claveProdServ', align: 'center', label: 'Clave Producto o Servicio', field: 'claveProdServ', sortable: true },
                { name: 'claveUnidad', align: 'center', label: 'Clave de Unidad', field: 'claveUnidad', sortable: true },
                { name: 'unidad', align: 'center', label: 'Unidad', field: 'unidad', sortable: true },
            ],
            loadingTabla: false,
            filter: '',
            dialogNuevoArticulo: false,
        }
    },
    computed: {
        itemsArticulos() {
            return this.$store.state.listaArticulosStore;
        },
        token() {
            return this.$store.state.usuario;
        },
    },
    created() {
        this.getArticulo();
    },
    methods: {
        nuevoArticulo() {
            this.iniciaizarObjeto();
            this.dialogNuevoArticulo = true
        },

        closeDialogArticulo() {
            this.dialogNuevoArticulo = false
        },

        editarArticulo(data) {
            console.log(data)
            this.$store.state.articuloStore = { ...data }
            this.dialogNuevoArticulo = true
        },

        async getArticulo() {
            this.loadingTabla = true
            try {
                let response = await axios.get("PuntoVenta/GetArticulosAsync/" + this.token.empresaBase);
                this.$store.state.listaArticulosStore = response.data;
                this.loadingTabla = false
            } catch (error) {
                console.log(error);
                this.loadingTabla = false
            }
        },

        async eliminarArticulo(value) {
            try {
                let response = await axios.put("PuntoVenta/DeleteArticuloAsync/" + this.token.empresaBase + "/" + value._id);
                console.log(response)

                var index = this.itemsArticulos.indexOf(value);
                this.itemsArticulos.splice(index, 1);

                this.$q.notify({ type: 'positive', message: `El artículo ha sido eliminado.` })

            } catch (error) {
                console.log(error);
                this.$q.notify({ type: 'negative', message: 'Error al eliminar el artículo, intentelo de nuevo.', color: 'red' })
            }
        },

        iniciaizarObjeto() {
            let articulo = {
                _id: '',
                noIdentificacion: '',
                cantidad: 1,
                descripcion: '',
                descuento: 0,
                concepto: '',
                objImp: { objImp: '' },
                impuestos: {
                    traslados: [],
                    retenciones: [],
                },

                almacenable: '',
                unidadCompra: '',
                unidadVenta: '',
                precioCompra: 0,
                precioVenta: 0,
                linea: '',
                marca: '',
                material: '',
                importeCompra: 0,
                importeVenta: 0,
            }
            this.$store.state.articuloStore = articulo
        },

        //CONCILIACION
        async openDialogConcilia() {
            this.$q.loading.show({ group: 'first', message: 'Consultando Claves de Productos o Servicios', spinnerColor: 'amber', messageColor: 'amber' })
            await this.GetClaveProdServ();
            this.$q.loading.show({ group: 'second', message: 'Consultando Claves de Unidad', spinnerColor: 'amber', messageColor: 'amber' })
            await this.GetClaveUnidad();
            this.$q.loading.show({ group: 'first', message: 'Consultando Objetos de Impuesto', spinnerColor: 'amber', messageColor: 'amber' })
            await this.GetObjImp();
            this.$q.loading.show({ group: 'first', message: 'Consultando Impuestos de Traslado', spinnerColor: 'amber', messageColor: 'amber' })
            await this.GetTraslados();
            this.$q.loading.show({ group: 'first', message: 'Consultando Impuestos de Retención', spinnerColor: 'amber', messageColor: 'amber' })
            await this.GetRetenciones();
            this.$q.loading.hide()
        },

        //CATALOGOS INICIALES
        async GetClaveProdServ() {
            if (this.$store.state.claveProdServStore.length != 0) {
                return
            }
            try {
                let response = await axios.get('https://api-erp.contago.com.mx/api/CatalogosSat/GetClaveProdServ/');
                this.$store.state.claveProdServStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async GetClaveUnidad() {
            if (this.$store.state.claveUnidadStore.length != 0) {
                return
            }
            try {
                let response = await axios.get('https://api-erp.contago.com.mx/api/CatalogosSat/GetClaveUnidad/');
                this.$store.state.claveUnidadStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async GetObjImp() {
            if (this.$store.state.objImpStore.length != 0) {
                return
            }
            try {
                let response = await axios.get('https://api-erp.contago.com.mx/api/CatalogosSat/GetObjImp/');
                this.$store.state.objImpStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async GetTraslados() {
            if (this.$store.state.trasladosStore.length != 0) {
                return
            }
            try {
                let response = await axios.get('https://api-erp.contago.com.mx/api/Impuestos/GetTraslados/');
                this.$store.state.trasladosStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async GetRetenciones() {
            if (this.$store.state.retencionesStore.length != 0) {
                return
            }
            try {
                let response = await axios.get('https://api-erp.contago.com.mx/api/Impuestos/GetRetenciones/');
                this.$store.state.retencionesStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

    }
}
</script>