<template>
    <q-layout view="hHh lpR fFf">
        <q-header elevated>
            <q-toolbar>
                <q-btn flat dense round icon="mdi-home" aria-label="Menu" @click="$router.push({ name: 'Home' })" />
                <q-toolbar-title>
                    <div class="text-h6 text-weight-bolder">TIENDA</div>
                </q-toolbar-title>
                <div class="text-h6 q-mr-lg">{{ $store.state.usuario.rfc }}</div>
                <q-btn flat round dense icon="mdi-account" @click="drawerPerfil = !drawerPerfil" />
            </q-toolbar>
        </q-header>

        <!-- DRAWER DERECHO -->
        <q-drawer v-model="drawerPerfil" side="right" bordered>
            <drawerPerfil></drawerPerfil>
        </q-drawer>

        <!-- DRAWER IZQUIERDO -->
        <q-drawer v-model="leftDrawerOpen" show-if-above :mini="miniState" @mouseover="miniState = false"
            @mouseout="miniState = true" :width="250" :breakpoint="500" bordered content-class="bg-dark">
            <q-scroll-area class="fit" :horizontal-thumb-style="{ opacity: 0 }">
                <q-list padding>
                    <q-item active clickable v-ripple @click="irPuntoVenta">
                        <q-item-section avatar>
                            <q-icon name="mdi-cash-register" />
                        </q-item-section>
                        <q-item-section>
                            Punto Venta
                        </q-item-section>
                    </q-item>
                    <q-separator />
                    <q-item active clickable v-ripple @click="irHistorial">
                        <q-item-section avatar>
                            <q-icon name="mdi-format-list-bulleted-square" />
                        </q-item-section>
                        <q-item-section>
                            Historial Venta
                        </q-item-section>
                    </q-item>
                    <q-separator />
                    <q-item active clickable v-ripple @click="irListaTurnos">
                        <q-item-section avatar>
                            <q-icon name="mdi-account-clock" />
                        </q-item-section>
                        <q-item-section>
                            Turnos
                        </q-item-section>
                    </q-item>
                    <q-separator />
                    <q-item active clickable v-ripple @click="irArticulos">
                        <q-item-section avatar>
                            <q-icon name="mdi-package-variant-closed" />
                        </q-item-section>
                        <q-item-section>
                            Articulos
                        </q-item-section>
                    </q-item>
                    <q-separator />
                    <q-item active clickable v-ripple @click="irClientes">
                        <q-item-section avatar>
                            <q-icon name="mdi-account-group" />
                        </q-item-section>
                        <q-item-section>
                            Clientes
                        </q-item-section>
                    </q-item>
                    <q-separator />
                    <q-item active clickable v-ripple @click="irListaUsuarios">
                        <q-item-section avatar>
                            <q-icon name="mdi-badge-account-outline" />
                        </q-item-section>
                        <q-item-section>
                            Usuarios Internos
                        </q-item-section>
                    </q-item>
                    <q-separator />
                </q-list>
            </q-scroll-area>
        </q-drawer>

        <q-page-container>
            <router-view />
        </q-page-container>

        <q-footer style="height: 30px;" bordered class="bg-dark text-white">
            <q-toolbar>
                <q-toolbar-title>
                </q-toolbar-title>
            </q-toolbar>
        </q-footer>
    </q-layout>
</template>

<script>
import { ref } from 'vue'
import axios from "axios";
import drawerPerfil from "../DrawerPerfil/DrawerPerfil.vue"


export default {

    name: 'MainLayout',
    components: {
        drawerPerfil
    },
    data() {
        return {
            drawerPerfil: false,
            isDarkMode: false,
            leftDrawerOpen: false,
            drawer: false,
            miniState: true,
        }
    },

    watch: {
        isDarkMode(val) {
            this.$q.dark.set(val);
        }
    },
    computed: {
        itemsConceptos() {
            return this.$store.state.listaArticulosStore;
        },
        token() {
            return this.$store.state.usuario;
        },
    },
    created() {
        this.getConceptos();
    },
    methods: {
        irPuntoVenta() {
            this.$router.push({ name: 'PuntoVenta' })
        },
        irHistorial() {
            this.$router.push({ name: 'Ventas' })
        },
        irListaTurnos() {
            this.$router.push({ name: 'ListaTurnos' })
        },
        irArticulos() {
            this.$router.push({ name: 'ListaArticulos' })
        },
        irClientes() {
            this.$router.push({ name: 'ListaClientes' })
        },
        irListaUsuarios() {
            this.$router.push({ name: 'ListaUsuariosInternos' })
        },
        async getConceptos() {
            try {
                let response = await axios.get("PuntoVenta/GetArticulosAsync/" + this.token.empresaBase);
                this.$store.state.listaArticulosStore = response.data;
            } catch (error) {
                console.log(error);
            }
        },
    }
}
</script>
