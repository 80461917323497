<template>
    <div>

        <q-dialog v-model="dialogNuevoUsuario" width="800px" persistent transition-show="scale" transition-hide="scale">
            <Usuario @cierraVentana="dialogNuevoUsuario = false"></Usuario>
        </q-dialog>

        <q-table title="Usuarios" :data="itemsUsuarios" :loading="loadingTabla" :columns="columns" row-key="_id"
            :filter="filter">
            <template v-slot:top-right>
                <q-input class="q-mr-lg" filled dense debounce="300" v-model="filter" placeholder="Filtrar">
                    <template v-slot:append>
                        <q-icon name="search" />
                    </template>
                </q-input>
                <q-btn round color="green" icon="mdi-plus" @click="nuevoUsuario()" class="q-mr-sm">
                    <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                        :offset="[10, 10]">Nuevo Usuario</q-tooltip>
                </q-btn>
                <q-btn round color="blue" icon="mdi-magnify" @click="getUsuario()" class="q-mr-sm">
                    <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                        :offset="[10, 10]">Consultar Usuarios</q-tooltip>
                </q-btn>
            </template>

            <template v-slot:loading>
                <q-inner-loading showing color="primary" />
            </template>

            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td key="rfc" :props="props">{{ props.row.rfc }}</q-td>
                    <q-td key="nombre" :props="props">{{ props.row.nombre }}</q-td>
                    <q-td key="usuario" :props="props">{{ props.row.usuario }}</q-td>
                    <q-td key="puesto" :props="props">{{ props.row.puesto }}</q-td>
                    <q-td key="departamento" :props="props">{{ props.row.departamento }}</q-td>
                    <q-td key="almacen" :props="props">{{ props.row.almacen.almacen }}</q-td>
                    <q-td key="correo" :props="props">{{ props.row.correo }}</q-td>
                    <q-td key="telefono" :props="props">{{ props.row.telefono }}</q-td>
                    <q-td auto-width>
                        <q-btn size="md" color="blue" rounded flat dense @click="editarUsuario(props.row)"
                            icon="mdi-pencil">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px">Editar</q-tooltip>
                        </q-btn>
                        <q-btn size="md" color="red" rounded flat dense @click="eliminarUsuario(props.row)"
                            icon="mdi-delete">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px">Eliminar</q-tooltip>
                        </q-btn>
                    </q-td>
                </q-tr>
            </template>
        </q-table>
    </div>
</template>
<script>
import axios from 'axios';
import { QSpinnerCube } from 'quasar';
import Usuario from './Usuario.vue';

export default {
    components: { Usuario },
    data() {
        return {
            columns: [
                { name: 'rfc', align: 'center', label: 'RFC', field: 'rfc', sortable: true, classes: 'bg-grey-2 ellipsis', headerClasses: 'bg-primary text-white' },
                { name: 'nombre', align: 'center', label: 'Nombre', field: 'nombre', sortable: true },
                { name: 'usuario', align: 'center', label: 'Usuario', field: 'usuario', sortable: true },
                { name: 'puesto', align: 'center', label: 'Puesto', field: 'puesto', sortable: true },
                { name: 'departamento', align: 'center', label: 'Departamento', field: 'departamento', sortable: true },
                { name: 'almacen', align: 'center', label: 'Almacen', field: 'almacen', sortable: true },
                { name: 'correo', align: 'center', label: 'Correo', field: 'correo', sortable: true },
                { name: 'telefono', align: 'center', label: 'Teléfono', field: 'telefono', sortable: true },
            ],
            loadingTabla: false,
            filter: '',
            dialogNuevoUsuario: false,
        }
    },
    computed: {
        itemsUsuarios() {
            return this.$store.state.listaUsuariosInternosStore;
        },
        token() {
            return this.$store.state.usuario;
        },
    },
    created() {
        this.getUsuario();
    },
    methods: {
        async getUsuario() {
            this.loadingTabla = true
            try {
                let response = await axios.get("PuntoVenta/GetUsuariosAsync/" + this.token.empresaBase);
                this.$store.state.listaUsuariosInternosStore = response.data;
                this.loadingTabla = false
            } catch (error) {
                console.log(error);
                this.loadingTabla = false
            }
        },

        nuevoUsuario() {
            this.iniciaizarObjeto();
            this.dialogNuevoUsuario = true
        },

        editarUsuario(data) {
            console.log(data)
            this.$store.state.usuarioInternoStore = { ...data }
            this.dialogNuevoUsuario = true
        },

        async eliminarUsuario(value) {
            try {
                let response = await axios.put("PuntoVenta/DeleteUsuarioAsync/" + this.token.empresaBase + "/" + value._id);
                console.log(response)

                var index = this.itemsUsuarios.indexOf(value);
                this.itemsUsuarios.splice(index, 1);

                this.$q.notify({ type: 'positive', message: `El artículo ha sido eliminado.` })

            } catch (error) {
                console.log(error);
                this.$q.notify({ type: 'negative', message: 'Error al eliminar el artículo, intentelo de nuevo.', color: 'red' })
            }
        },

        iniciaizarObjeto() {
            let usuario = {
                _id: '',
                rfc: '',
                nombre: '',
                usuario: '',
                password: '',
                puesto: '',
                departamento: '',
                permisos: [],
                almacen: { almacen: '' },
                serie: '',
                folio: '',
                correo: '',
                telefono: '',
                usuarioModificador: '',
                usuarioCreado: this.token.nombre
            }
            this.$store.state.usuarioInternoStore = usuario
        },
    }
}
</script>