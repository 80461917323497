const qr = require('qrcode');

// Función para generar el código QR en base64
async function generarCodigoQR(ordenesCompras) {

    const cadenaQR = ordenesCompras

    try {
        let base64QR = "";
        await qr.toDataURL(cadenaQR, (err, url) => {
            if (err) {
                throw err;
            }
            base64QR = url.split(',')[1];
        });

        return base64QR;
    } catch (error) {
        throw error;
    }
}

module.exports = {
    generarCodigoQR
};
