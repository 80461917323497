<template>
    <div class="row q-col-gutter-sm">
        <div class="col-12">
            <q-bar dense class="bg-primary text-white">
                <q-space />
                <div>CORTE DE CAJA</div>
                <q-space />
            </q-bar>
        </div>
        <div class="col-12">
            <q-table class="shadow-0" :data="data" :columns="columns" row-key="name" :rows-per-page-options="[0]"
                hide-bottom>
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td key="descripcion" :props="props">{{ props.row.descripcion
                            }}</q-td>
                        <q-td key="monto" :props="props">{{ formatCurrency(props.row.monto) }}</q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>
        <div class="col-12">
            <q-bar dense class="bg-primary text-white">
                <q-space />
                <div>EFECTIVO EN CAJA</div>
                <q-space />
            </q-bar>
        </div>
        <div class="col-12">
            <q-field class="full-width q-mx-lg q-pt-md" v-model="efectivoFinal" label="Efectivo Final" dense filled>
                <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                    <vue-number :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                        v-show="floatingLabel" v-bind="importeFormat"></vue-number>
                </template>
            </q-field>
        </div>

        <div class="col-12 col-md-6">
            <q-btn color="red" class="full-width q-mt-md" dense label="CANCELAR" @click="cerrarDialog()" />
        </div>
        <div class="col-12 col-md-6">
            <q-btn color="primary" class="full-width q-mt-md" dense label="cerrar turno" @click="cerrarCaja()" />
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { QSpinnerCube } from 'quasar';
import { component as VueNumber } from '@coders-tm/vue-number-format'
export default {
    components: { VueNumber, },
    data() {
        return {
            importeFormat: {
                decimal: '.',
                separator: ',',
                prefix: '',
                precision: 2,
                masked: false,
                minimumFractionDigits: 2,
            },
            data: [],
            columns: [
                {
                    name: 'descripcion',
                    required: true,
                    label: 'Forma de Pago',
                    align: 'center',
                    sortable: true,
                    classes: 'bg-grey-2 ellipsis text-left',
                    headerClasses: 'bg-primary text-white',
                    field: 'descripcion'
                },
                { name: 'monto', align: 'center', label: 'Monto', classes: 'text-right', field: 'monto', sortable: true },
            ],
            efectivoFinal: 0,
        }
    },
    computed: {

        token() {
            return this.$store.state.usuario;
        },
        turno() {
            return this.$store.state.turnoPuntoVenta
        }
    },
    created() {
        this.GetCorteCaja()
    },
    methods: {
        cerrarDialog() {
            this.$emit('closeCerrarSesion')
        },

        async GetCorteCaja() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'yellow', spinnerSize: 100, message: 'Generando corte de caja. Espere...', messageColor: 'yellow' })
            try {
                let response = await axios.get('PuntoVenta/GetCorteCaja/' + this.token.empresaBase + '/' + this.turno.turno)
                console.log(response.data)
                this.data = response.data
                this.$q.loading.hide()
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
            }
        },

        async cerrarCaja() {
            let objeto = {
                _id: this.$store.state.turnoPuntoVenta._id,
                estatus: 'Cerrado',
                fechaSalida: '',
                horaSalida: '',
                caja: this.$store.state.turnoPuntoVenta.caja,
                efectivoInicial: this.$store.state.turnoPuntoVenta.efectivoInicial,
                efectivoFinal: this.efectivoFinal
            }
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'yellow', spinnerSize: 100, message: 'Cancelando acceso al punto de venta. Espere...', messageColor: 'yellow' })
            try {
                let response = await axios.put('PuntoVenta/PutTurnoAsync/' + this.token.empresaBase, objeto)
                this.$store.state.turnoPuntoVenta = null
                this.$router.push({ name: 'Home' })
                console.log(response.data)
                this.$q.loading.hide()
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
            }
        },
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
    }
}
</script>