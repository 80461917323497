import { render, staticRenderFns } from "./PuntoVenta.vue?vue&type=template&id=b5fff492"
import script from "./PuntoVenta.vue?vue&type=script&lang=js"
export * from "./PuntoVenta.vue?vue&type=script&lang=js"
import style0 from "./PuntoVenta.vue?vue&type=style&index=0&id=b5fff492&prod&lang=css"
import style1 from "./PuntoVenta.vue?vue&type=style&index=1&id=b5fff492&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpinnerPie from 'quasar/src/components/spinner/QSpinnerPie.js';
import QBar from 'quasar/src/components/bar/QBar.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QField from 'quasar/src/components/field/QField.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QSplitter from 'quasar/src/components/splitter/QSplitter.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QCardSection,QIcon,QCardActions,QBtn,QSpinnerPie,QBar,QInput,QField,QSelect,QTable,QTabs,QTab,QSeparator,QTabPanels,QTabPanel,QTr,QTd,QTooltip,QToolbar,QSplitter,QSpace,QAvatar});qInstall(component, 'directives', {ClosePopup});
