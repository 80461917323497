<template>
    <div>
        <q-dialog v-model="dialogCierreCaja" persistent>
            <q-card style="min-width: 700px">
                <q-bar class="text-primary bg-dark">
                    <div>CORTE POR TURNO</div>
                    <q-space />
                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>
                <q-card-section>
                    <div class="row q-col-gutter-sm">
                        <div class="col-12">
                            <q-table class="shadow-0" :data="data" :columns="columnsCorte" row-key="name"
                                :rows-per-page-options="[0]" hide-bottom>
                                <template v-slot:body="props">
                                    <q-tr :props="props">
                                        <q-td key="descripcion" :props="props">{{ props.row.descripcion
                                            }}</q-td>
                                        <q-td key="monto" :props="props">{{ formatCurrency(props.row.monto) }}</q-td>
                                    </q-tr>
                                </template>
                            </q-table>
                        </div>
                    </div>
                </q-card-section>
            </q-card>
        </q-dialog>
        <q-table title="Lista de Turnos" :data="dataTurnos" :loading="loadingTabla" :columns="columns" row-key="_id">
            <template v-slot:top-right>
                <q-input v-model="fechaMasked" filled label="Fecha" class="q-mr-sm" name="event" outlined dense>
                    <template v-slot:before>
                        <q-icon name="event" color="primary" />
                    </template>
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="fecha">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Ok" color="primary" flat />
                            </div>
                        </q-date>
                    </q-popup-proxy>
                </q-input>
                <q-btn round color="blue" icon="mdi-magnify" @click="getListaTurnos()" />
            </template>

            <template v-slot:loading>
                <q-inner-loading showing color="primary" />
            </template>

            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td auto-width>
                        <q-btn size="md" color="blue" rounded flat dense @click="GetCorteCaja(props.row)"
                            icon="mdi-format-list-text">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px">Ver Detalles</q-tooltip>
                        </q-btn>
                    </q-td>
                    <q-td key="turno" :props="props">{{ props.row.turno }}</q-td>
                    <q-td key="nombre" :props="props">{{ props.row.usuarioInterno.nombre }}</q-td>
                    <q-td key="caja" :props="props">{{ props.row.caja }}</q-td>
                    <q-td key="fechaEntrada" :props="props">{{ props.row.fechaEntrada }}</q-td>
                    <q-td key="horaEntrada" :props="props">{{ props.row.horaEntrada }}</q-td>
                    <q-td key="fechaSalida" :props="props">{{ props.row.fechaSalida }}</q-td>
                    <q-td key="horaSalida" :props="props">{{ props.row.horaSalida }}</q-td>
                    <q-td key="efectivoInicial" :props="props">{{ formatCurrency(props.row.efectivoInicial) }}</q-td>
                    <q-td key="efectivoFinal" :props="props">{{ formatCurrency(props.row.efectivoFinal) }}</q-td>
                    <q-td key="estatus" :props="props">
                        <q-badge v-if="props.row.estatus == 'Abierta'" color="green">
                            {{ props.row.estatus }}
                        </q-badge>
                        <q-badge v-if="props.row.estatus == 'Cancelado'" color="red">
                            {{ props.row.estatus }}
                        </q-badge>
                        <q-badge v-if="props.row.estatus == 'Cerrado'" color="orange">
                            {{ props.row.estatus }}
                        </q-badge>
                    </q-td>
                </q-tr>
            </template>
        </q-table>
    </div>
</template>
<script>
import axios from 'axios';
import { QSpinnerCube } from 'quasar';
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import moment from 'moment';

export default {
    data() {
        return {
            columns: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones' },
                { name: 'turno', align: 'center', label: 'Turno', field: 'turno', sortable: true, classes: 'bg-grey-2 ellipsis', headerClasses: 'bg-primary text-white' },
                { name: 'nombre', align: 'center', label: 'Nombre de Usuario', field: 'nombre', sortable: true, },
                { name: 'caja', align: 'center', label: 'Caja', field: 'caja', sortable: true, },
                { name: 'fechaEntrada', align: 'center', label: 'Fecha de Entrada', field: 'fechaEntrada', sortable: true, },
                { name: 'horaEntrada', align: 'center', label: 'Hora de Entrada', field: 'horaEntrada', sortable: true, },
                { name: 'fechaSalida', align: 'center', label: 'Fecha de Salida', field: 'fechaSalida', sortable: true, },
                { name: 'horaSalida', align: 'center', label: 'Hora de Salida', field: 'horaSalida', sortable: true, },
                { name: 'efectivoInicial', align: 'center', label: 'Efectivo Inicial', field: 'efectivoInicial', sortable: true, },
                { name: 'efectivoFinal', align: 'center', label: 'Efectivo Final', field: 'efectivoFinal', sortable: true, },
                { name: 'estatus', align: 'center', label: 'Estatus', field: 'estatus', sortable: true, },
            ],
            loadingTabla: false,
            dataTurnos: [],
            fecha: new Date(),

            data: [],
            columnsCorte: [
                {
                    name: 'descripcion',
                    required: true,
                    label: 'Forma de Pago',
                    align: 'center',
                    sortable: true,
                    classes: 'bg-grey-2 ellipsis text-left',
                    headerClasses: 'bg-primary text-white',
                    field: 'descripcion'
                },
                { name: 'monto', align: 'center', label: 'Monto', classes: 'text-right', field: 'monto', sortable: true },
            ],
            dialogCierreCaja: false
        }
    },
    computed: {


        token() {
            return this.$store.state.usuario;
        },

        fechaMasked() {
            moment.locale('es-mx');
            return this.fecha ? moment(this.fecha).format('DD-MMMM-yyyy') : ''
        },
    },
    created() {
        this.getListaTurnos();
    },
    methods: {
        async getListaTurnos() {
            this.loadingTabla = true
            let fecha_ = new Date(this.fecha);
            let fecha = format(fecha_, "yyyy-MM-dd");
            try {
                let response = await axios.get("PuntoVenta/GetTurnosAsync/" + this.token.empresaBase + '/' + fecha);
                this.dataTurnos = response.data;
                this.loadingTabla = false
            } catch (error) {
                console.log(error);
                this.loadingTabla = false
            }
        },
        async GetCorteCaja(item) {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'yellow', spinnerSize: 100, message: 'Generando corte de caja. Espere...', messageColor: 'yellow' })
            try {
                let response = await axios.get('PuntoVenta/GetCorteCaja/' + this.token.empresaBase + '/' + item.turno)
                console.log(response.data)
                this.data = response.data
                this.dialogCierreCaja = true
                this.$q.loading.hide()
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
            }
        },
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
    }
}
</script>