<template>
    <q-card style="width: 1200px; max-width: 80vw;">
        <q-card-section>
            <q-toolbar>
                <q-toolbar-title>
                    Artículo
                </q-toolbar-title>
                <q-btn v-if="articulo._id == ''" dense round color="green" icon="mdi-content-save" class="q-mr-sm"
                    @click="postArticulo()">
                    <q-tooltip>
                        Guardar
                    </q-tooltip>
                </q-btn>
                <q-btn v-else round color="orange" dense icon="mdi-content-save" class="q-mr-sm"
                    @click="editarArticulo()">
                    <q-tooltip>
                        Editar
                    </q-tooltip>
                </q-btn>
                <q-btn round color="red" icon="mdi-close" dense @click="cierraVentana()">
                    <q-tooltip>
                        Cerrar
                    </q-tooltip>
                </q-btn>
            </q-toolbar>
            <q-separator class="full-width" color="primary" inset size="4px" />
        </q-card-section>
        <q-card-section style="max-height: 50vh" class="scroll">
            <div class="row q-col-gutter-sm">
                <!-- <div class="col-12">
                    <q-select dense v-model="articulo.claveProdServ" :options="listaFiltroClaveProdServicio" filled
                        class="full-width " color="black" emit-value map-options use-input input-debounce="0"
                        label="Clave del SAT" @filter="filtroClaveProdServicio" option-label="claveProdServ"></q-select>
                </div> -->
                <div class="col-12">
                    <q-input dense v-model="articulo.noIdentificacion" class="full-width" filled
                        label="No. Identificación" />
                </div>
                <!-- <div class="col-12 col-md-6">
                    <q-select dense v-model="articulo.claveUnidad" :options="listaFiltroClaveUnidad" filled
                        class="full-width" color="black" use-input input-debounce="0" label="Undiad del SAT"
                        @filter="filtroClaveUnidad" option-label="claveUnidad"></q-select>
                </div> -->
                <div class="col-12 col-md-6">
                    <q-input dense v-model="articulo.unidadCompra" class="full-width" filled label="Unidad de Compra" />
                </div>
                <div class="col-12 col-md-6">
                    <q-input dense v-model="articulo.unidadVenta" class="full-width" filled label="Unidad de Venta" />
                </div>
                <div class="col-12">
                    <q-input dense v-model="articulo.descripcion" class="full-width" filled label="Descripción" />
                </div>

                <div class="col-12 col-md-3">
                    <q-field v-model="articulo.cantidad" label="Cantidad" dense filled>
                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                            <vue-number :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                v-show="floatingLabel" v-bind="importeFormat"></vue-number>
                        </template>
                    </q-field>
                </div>
                <div class="col-12 col-md-9 ">
                    <q-select dense class="full-width" filled v-model="articulo.almacenable" :options="['Si', 'No']"
                        label="Almacenable" />
                </div>
                <div class="col-12">
                    <q-input dense v-model="articulo.linea" class="full-width" filled label="Linea" />
                </div>

                <div class="col-12">
                    <q-input dense v-model="articulo.marca" class="full-width" filled label="Marca" />
                </div>

                <div class="col-12">
                    <q-input dense v-model="articulo.material" class="full-width" filled label="Material" />
                </div>

                <div class="col-12 col-md-3">
                    <q-field v-model="articulo.precioCompra" label="Precio de Compra" dense filled>
                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                            <vue-number :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                v-show="floatingLabel" v-bind="importeFormat"></vue-number>
                        </template>
                    </q-field>
                </div>
                <div class="col-12 col-md-3">
                    <q-field v-model="articulo.precioVenta" label="Precio de Venta" dense filled>
                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                            <vue-number :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                v-show="floatingLabel" v-bind="importeFormat"></vue-number>
                        </template>
                    </q-field>
                </div>

                <div class="col-12 col-md-9 ">
                    <q-select dense class="full-width" filled v-model="articulo.objImp" :options="objImp"
                        label="Objeto de Impuestos" option-label="objImp" />
                </div>
                <template v-if="articulo.objImp.clave === '02'">
                    <div class="col-12 col-md-6">
                        <q-table dense :rows-per-page-options="[0]" hide-bottom class="shadow-0" title="Traladados"
                            :data="traslados" :columns="columns" row-key="nombre" selection="multiple"
                            :selected.sync="articulo.impuestos.traslados" />
                    </div>
                    <div class="col-12 col-md-6">
                        <q-table dense :rows-per-page-options="[0]" hide-bottom class="shadow-0" title="Retenidos"
                            :data="retenciones" :columns="columns" row-key="nombre" selection="multiple"
                            :selected.sync="articulo.impuestos.retenciones" />
                    </div>
                </template>
            </div>
        </q-card-section>
    </q-card>
</template>
<script>
import axios from 'axios';
import { component as VueNumber } from '@coders-tm/vue-number-format'

import { QSpinnerCube } from 'quasar'
export default {
    components: { VueNumber },
    data() {
        return {
            lista: null,
            text: '',
            price: 0,
            model: null,
            options: [],
            selected: [],

            columns: [
                { name: 'impuesto', label: 'Impuesto', field: 'impuesto', align: 'center' },
                { name: 'tipoFactor', label: 'Tipo Factor', field: 'tipoFactor', align: 'center' },
                { name: 'tasaOCuota', label: 'Tasa o Cuota', field: 'tasaOCuota', align: 'center' }
            ],
            otrasCaracteristicas: false,
            almacenable: true,
            listaFiltroClaveProdServicio: null,
            listaFiltroClaveUnidad: null,

            importeFormat: {
                decimal: '.',
                separator: ',',
                prefix: '',
                precision: 6,
                masked: false,
                minimumFractionDigits: 6,
            },
        }
    },
    computed: {
        claveProdServ() {
            return this.$store.state.claveProdServStore
        },
        claveUnidad() {
            return this.$store.state.claveUnidadStore
        },
        objImp() {
            return this.$store.state.objImpStore
        },

        retenciones() {
            return this.$store.state.retencionesStore
        },
        traslados() {
            return this.$store.state.trasladosStore
        },
        articulo() {
            return this.$store.state.articuloStore
        },
        token() {
            return this.$store.state.usuario;
        },
    },
    created() {
        this.iniciaizar();
    },
    methods: {
        async iniciaizar() {
            this.$q.loading.show({ group: 'first', message: 'Consultando Claves de Productos o Servicios', spinnerColor: 'amber', messageColor: 'amber' })
            await this.GetClaveProdServ();
            this.$q.loading.show({ group: 'second', message: 'Consultando Claves de Unidad', spinnerColor: 'amber', messageColor: 'amber' })
            await this.GetClaveUnidad();
            this.$q.loading.show({ group: 'first', message: 'Consultando Objetos de Impuesto', spinnerColor: 'amber', messageColor: 'amber' })
            await this.GetObjImp();
            this.$q.loading.show({ group: 'first', message: 'Consultando Impuestos', spinnerColor: 'amber', messageColor: 'amber' })
            await this.GetImpuestos();
            this.$q.loading.hide()
        },

        async GetImpuestos() {
            if (this.$store.state.trasladosStore.length != 0) {
                return;
            } else {
                try {
                    let response = await axios.get('CatalogosSat/GetCatImpuestosSatAsync')
                    let x = response.data;
                    this.$store.state.trasladosStore = x.filter(f => f.tipo === 'Traslado');
                    this.$store.state.retencionesStore = x.filter(f => f.tipo === 'Retencion');
                } catch (error) {
                    console.log(error)
                }
            }
        },
        async GetClaveProdServ() {
            if (this.$store.state.claveProdServStore.length != 0) {
                return;
            } else {
                try {
                    let response = await axios.get('CatalogosSat/GetCatClaveProdServAsync')
                    let x = response.data;
                    this.$store.state.claveProdServStore = [...x]
                } catch (error) {
                    console.log(error)
                }
            }
        },
        async GetClaveUnidad() {
            if (this.$store.state.claveUnidadStore.length != 0) {
                return;
            } else {
                try {
                    let response = await axios.get('CatalogosSat/GetCatClaveUnidadAsync')
                    let x = response.data;
                    this.$store.state.claveUnidadStore = [...x]
                } catch (error) {
                    console.log(error)
                }
            }
        },

        async GetObjImp() {
            if (this.$store.state.objImpStore.length != 0) {
                return;
            } else {
                try {
                    let response = await axios.get('CatalogosSat/GetCatObjImpAsync')
                    let x = response.data;
                    this.$store.state.objImpStore = [...x]
                } catch (error) {
                    console.log(error)
                }
            }
        },

        filtroClaveProdServicio(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.listaFiltroClaveProdServicio = this.claveProdServ.filter(v => v.claveProdServ.toLocaleLowerCase().indexOf(needle) > -1)
            })
        },

        filtroClaveUnidad(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.listaFiltroClaveUnidad = this.claveUnidad.filter(v => v.claveUnidad.toLocaleLowerCase().indexOf(needle) > -1)
            })
        },

        setModelClaveProductoServicio(val) {
            this.articulo.claveProdServ = val
        },

        setModelClaveUnidad(val) {
            this.articulo.claveUnidad = val
        },

        async postArticulo() {


            if (this.articulo.noIdentificacion == '') {
                this.$q.notify({
                    type: 'warning',
                    message: `Escriba el NoIdentificación.`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }

            if (this.articulo.unidadCompra == '') {
                this.$q.notify({
                    type: 'warning',
                    message: `Ingrese la unidad de compra.`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }
            if (this.articulo.unidadVenta == '') {
                this.$q.notify({
                    type: 'warning',
                    message: `Ingrese la unidad de venta.`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }
            if (this.articulo.descripcion == '') {
                this.$q.notify({
                    type: 'warning',
                    message: `Ingrese la descripción del artículo.`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }
            if (this.articulo.objImp.objImp == '') {
                this.$q.notify({
                    type: 'warning',
                    message: `Seleccione si es objeto de impuestos.`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }

            if (this.articulo.objImp.clave === '02') {
                if (this.articulo.impuestos.traslados.length + this.articulo.impuestos.retenciones.length == 0) {
                    this.$q.notify({
                        type: 'warning',
                        message: `Indique al menos un impuesto trasladado o retenido`,
                        actions: [
                            { label: 'Cerrar', color: 'white', handler: () => { } }
                        ]
                    })
                    return;
                }
            }
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'yellow', spinnerSize: 140, message: 'Guardando artículo. Espere...', messageColor: 'yellow' })
            this.articulo.concepto = this.articulo.noIdentificacion + ' | ' + this.articulo.descripcion
            console.log(this.articulo)
            try {
                let response = await axios.post('PuntoVenta/PostArticuloAsync/' + this.token.empresaBase, this.articulo)
                console.log(response)

                this.articulo._id = response.data
                this.$store.state.listaArticulosStore.push(this.articulo)

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `Artículo ha sido guardado exitosamente.` })
                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },

        async editarArticulo() {
            if (this.articulo.noIdentificacion == '') {
                this.$q.notify({
                    type: 'warning',
                    message: `Escriba el NoIdentificación.`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }

            if (this.articulo.unidadCompra == '') {
                this.$q.notify({
                    type: 'warning',
                    message: `Ingrese la unidad de compra.`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }
            if (this.articulo.unidadVenta == '') {
                this.$q.notify({
                    type: 'warning',
                    message: `Ingrese la unidad de venta.`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }
            if (this.articulo.descripcion == '') {
                this.$q.notify({
                    type: 'warning',
                    message: `Ingrese la descripción del artículo.`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }
            if (this.articulo.objImp.objImp == '') {
                this.$q.notify({
                    type: 'warning',
                    message: `Seleccione si es objeto de impuestos.`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }

            if (this.articulo.objImp.clave === '02') {
                if (this.articulo.impuestos.traslados.length + this.articulo.impuestos.retenciones.length == 0) {
                    this.$q.notify({
                        type: 'warning',
                        message: `Indique al menos un impuesto trasladado o retenido`,
                        actions: [
                            { label: 'Cerrar', color: 'white', handler: () => { } }
                        ]
                    })
                    return;
                }
            }
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'yellow', spinnerSize: 140, message: 'Editando artículo. Espere...', messageColor: 'yellow' })
            console.log(this.articulo)

            try {
                let response = await axios.put('PuntoVenta/PutArticuloAsync/' + this.token.empresaBase, this.articulo)
                console.log(response.data)

                // BUSCAMOS EL INDICE
                let indice = this.$store.state.listaArticulosStore.findIndex(x => x._id === this.articulo._id);
                Object.assign(this.$store.state.listaArticulosStore[indice], this.articulo)

                this.$q.notify({ type: 'positive', message: `El artículo ha sido editado exitosamente.` })
                this.$q.loading.hide()
                this.cierraVentana()
            } catch (error) {
                console.log(error)
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
                this.$q.loading.hide()
            }
        },

        cierraVentana() {
            this.$emit('cierraVentana')
        },
    }
}
</script>