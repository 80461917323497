<template>
    <q-card style="width: 1200px; max-width: 80vw;">
        <q-card-section>
            <q-toolbar>
                <q-toolbar-title>
                    Usuario
                </q-toolbar-title>
                <q-btn v-if="usuario._id == ''" dense round color="green" icon="mdi-content-save" class="q-mr-sm"
                    @click="postUsuario()">
                    <q-tooltip>
                        Guardar
                    </q-tooltip>
                </q-btn>
                <q-btn v-else round color="orange" dense icon="mdi-content-save" class="q-mr-sm"
                    @click="editarUsuario()">
                    <q-tooltip>
                        Editar
                    </q-tooltip>
                </q-btn>
                <q-btn round color="red" icon="mdi-close" dense @click="cierraVentana()">
                    <q-tooltip>
                        Cerrar
                    </q-tooltip>
                </q-btn>
            </q-toolbar>
            <q-separator class="full-width" color="primary" inset size="4px" />
        </q-card-section>
        <q-card-section style="max-height: 50vh" class="scroll">
            <div class="row q-col-gutter-sm">
                <div class="col-12">
                    <q-input dense v-model="usuario.rfc" class="full-width" filled label="RFC" />
                </div>
                <div class="col-12">
                    <q-input dense v-model="usuario.nombre" class="full-width" filled label="Nombre" />
                </div>
                <div class="col-12 col-md-6">
                    <q-input dense v-model="usuario.usuario" class="full-width" filled label="Alias/Usuario" />
                </div>
                <div class="col-12 col-md-6">
                    <q-input dense v-model="usuario.password" class="full-width" filled label="Contraseña" />
                </div>

                <div class="col-12 col-md-6">
                    <q-input dense v-model="usuario.puesto" class="full-width" filled label="Puesto" />
                </div>

                <div class="col-12 col-md-6 ">
                    <q-input dense v-model="usuario.departamento" class="full-width" filled label="Departamento" />
                </div>
                <div class="col-12 col-md-12 ">
                    <q-select multiple use-chips dense v-model="usuario.permisos" :options="['Tienda', 'Administrador']"
                        filled class="full-width" label="Permisos"></q-select>
                </div>
                <div class="col-12 col-md-6 ">
                    <q-select dense v-model="usuario.almacen" option-label="almacen" :options="[]" filled
                        class="full-width" label="Almacen"></q-select>
                </div>
                <div class="col-12 col-md-6 ">
                    <q-input dense v-model="usuario.serie" class="full-width" filled label="Serie" />
                </div>
                <div class="col-12 col-md-6 ">
                    <q-input dense v-model="usuario.correo" class="full-width" filled label="Correo" />
                </div>
                <div class="col-12 col-md-6 ">
                    <q-input dense v-model="usuario.telefono" class="full-width" filled label="Teléfono" />
                </div>
            </div>
        </q-card-section>
    </q-card>
</template>
<script>
import axios from 'axios';
import { component as VueNumber } from '@coders-tm/vue-number-format'

import { QSpinnerCube } from 'quasar'
export default {
    components: { VueNumber },
    data() {
        return {
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        usuario() {
            return this.$store.state.usuarioInternoStore;
        }
    },
    created() {
    },
    methods: {
        async postUsuario() {
            if (this.usuario.rfc == '') {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique el RFC del usuario.`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }

            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'yellow', spinnerSize: 140, message: 'Guardando usuario. Espere...', messageColor: 'yellow' })
            console.log(this.usuario)
            try {
                let response = await axios.post('PuntoVenta/PostUsuarioAsync/' + this.token.empresaBase, this.usuario)
                console.log(response)

                this.usuario._id = response.data
                this.$store.state.listaUsuariosInternosStore.push(this.usuario)

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `El usuario ha sido guardado exitosamente.` })
                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },

        async editarUsuario() {
            if (this.usuario.rfc == '') {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique el RFC del usuario.`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'yellow', spinnerSize: 140, message: 'Editando usuario. Espere...', messageColor: 'yellow' })

            try {
                let response = await axios.put('PuntoVenta/PutUsuarioAsync/' + this.token.empresaBase, this.usuario)
                console.log(response.data)

                // BUSCAMOS EL INDICE
                let indice = this.$store.state.listaUsuariosInternosStore.findIndex(x => x._id === this.usuario._id);
                Object.assign(this.$store.state.listaUsuariosInternosStore[indice], this.usuario)

                this.$q.notify({ type: 'positive', message: `El usuario ha sido editado exitosamente.` })
                this.$q.loading.hide()
                this.cierraVentana()
            } catch (error) {
                console.log(error)
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
                this.$q.loading.hide()
            }
        },

        cierraVentana() {
            this.$emit('cierraVentana')
        },
    }
}
</script>