import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import store from '../store/index'

import MainPage from '../components/Inicio/MainPage.vue'
import Home from '../views/Home.vue'
import Configuracion from '../components/Configuracion/Configuracion.vue'
import UsuariosInternos from '../components/UsuariosInternos/ListaUsuariosInternos.vue'

import ListaConceptos from '../components/Conceptos/ListaConceptos.vue'
import ListaClientes from '../components/Clientes/ListaClientes.vue'

import MainPuntoVenta from '../components/PuntoVenta/MainPuntoVenta.vue'
import PuntoVenta from '../components/PuntoVenta/PuntoVenta.vue'
import LoginPuntoVenta from '../components/PuntoVenta/LoginPuntoVentas.vue'
import ListaTurnos from '../components/PuntoVenta/ListaTurnos.vue'
import Ventas from '../components/PuntoVenta/Ventas.vue'
import ListaArticulos from '../components/PuntoVenta/Articulos/ListaArticulos.vue'
import ListaUsuariosInternos from '../components/PuntoVenta/UsuariosInternos/ListaUsuarios.vue'

import MainComprobantes from '../components/Comprobantes/MainComprobantes.vue'
import Comprobantes from '../components/Comprobantes/Comprobantes.vue'
// import ListFacturacion from '../components/Comprobantes/Facturacion/ListFacturacion.vue'
import ListFacturacionGas from '../components/Comprobantes/facturacionGas/ListFacturacionGas.vue'

import MainFacturacion from '../components/Comprobantes/Facturacion/MainFacturacion.vue'
import ListFacturacion from '../components/Comprobantes/Facturacion/ListFacturacion.vue'

import MainNotaCredito from '../components/Comprobantes/NotaCredito/MainNotaCredito.vue'
import ListNotaCredito from '../components/Comprobantes/NotaCredito/ListNotaCredito.vue'

import MainIngresoCartaPorte from '../components/Comprobantes/IngresoCartaPorte/MainIngresoCartaPorte.vue'
import ListCartaPorte from '../components/Comprobantes/IngresoCartaPorte/ListCartaPorte.vue'
import ListaDomicilios from '../components/CatalogosCartaPorte/Domicilios/ListaDomicilios.vue'
import ListaFigurasDeTransporte from '../components/CatalogosCartaPorte/FigurasDeTransportes/ListaFigurasDeTransporte.vue'
import ListaIdentificacionVehicular from '../components/CatalogosCartaPorte/IdentificacionVehicular/ListaIdentificacionVehicular.vue'
import ListaMercancias from '../components/CatalogosCartaPorte/Mercancias/ListaMercancias.vue'
import ListaRemolques from '../components/CatalogosCartaPorte/Remolques/ListaRemolques.vue'
import ListaSeguros from '../components/CatalogosCartaPorte/Seguros/ListaSeguros.vue'
import ListaUbicaciones from '../components/CatalogosCartaPorte/Ubicaciones/ListaUbicaciones.vue'

import MainComercioExterior from '../components/Comprobantes/ComercioExterior/MainComercioExterior.vue'
import ListComercioExterior from '../components/Comprobantes/ComercioExterior/ListComercioExterior.vue'
import ListaReceptor from '../components/CatalogoComercioExterior/Receptor/ListaReceptor.vue'
import ListaDestinatarios from '../components/CatalogoComercioExterior/Destinatario/ListaDestinatarios.vue'
import ListaEmisor from '../components/CatalogoComercioExterior/Emisor/ListaEmisor.vue'
import ListaPropietario from '../components/CatalogoComercioExterior/Propietario/ListaPropietarios.vue'
import ListaMercanciasCE from '../components/CatalogoComercioExterior/MercanciasCE/ListaMercanciasCE.vue'

import MainComplementoPago from '../components/Comprobantes/ComplementoPago/MainComplementoPago.vue'
import ListaComplementoPago from '../components/Comprobantes/ComplementoPago/ListaComplementoPago.vue'

import MainVentas from '../components/Ventas/MainVentas.vue'
import ListaCotizacion from '../components/Ventas/Cotización/ListaCotizacion.vue'
import ListaPedidos from '../components/Ventas/Pedidos/ListaPedidos.vue'
import ListaProtocolos from '../components/Ventas/Protocolos/ListaProtocolos.vue'
import ListaRemision from '../components/Ventas/Remision/ListaRemision.vue'
import ListaProyectos from '../components/Proyectos/ListaProyectos.vue'

import MainCompras from '../components/Compras/MainCompras.vue'
import ListaPedidosC from '../components/Compras/Pedidos/ListaPedidos.vue'
import ListaCotizacionC from '../components/Compras/Cotizacion/ListaCotizacion.vue'
import ListaOrdenCompraC from '../components/Compras/OrdenCompra/ListaOrdenCompra.vue'
import ListaProveedor from '../components/Proveedores/ListaProveedor.vue'

import MainInventarios from '../components/Inventarios/MainInventarios.vue';
import ListAlmacenes from '../components/Inventarios/Almacen/ListAlmacenes.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/', component: MainPage, meta: { Administrador: true },
    children: [
      { path: 'Home', component: Home, name: 'Home', meta: { Administrador: true } },
      { path: 'Configuracion', component: Configuracion, name: 'Configuracion', meta: { Administrador: true } },
      // { path: 'UsuariosInternos', component: UsuariosInternos, name: 'UsuariosInternos', meta: { Administrador: true } },
      { path: 'Comprobantes', component: Comprobantes, name: 'Comprobantes', meta: { Administrador: true } },
    ],
  },
  { path: '/about', name: 'About', component: () => import(/* webpackChunkName: "about" */ '../views/About.vue') },
  { path: '/Login', name: 'Login', component: Login, meta: { libre: true } },
  {
    path: '/MainPuntoVenta', component: MainPuntoVenta, meta: { Administrador: true },
    children: [
      { path: 'LoginPuntoVenta', component: LoginPuntoVenta, name: 'LoginPuntoVenta', meta: { Administrador: true } },
      // { path: 'UsuariosInternos', component: UsuariosInternos, name: 'UsuariosInternos', meta: { Administrador: true } },
      { path: 'Configuracion', component: Configuracion, name: 'Configuracion', meta: { Administrador: true } },
      { path: 'Ventas', component: Ventas, name: 'Ventas', meta: { Administrador: true } },
      { path: 'ListaTurnos', component: ListaTurnos, name: 'ListaTurnos', meta: { Administrador: true } },
      { path: 'PuntoVenta', component: PuntoVenta, name: 'PuntoVenta', meta: { Administrador: true } },
      { path: 'ListaArticulos', component: ListaArticulos, name: 'ListaArticulos', meta: { Administrador: true } },
      { path: 'ListaClientes', component: ListaClientes, name: 'ListaClientes', meta: { Administrador: true } },
      { path: 'ListaUsuariosInternos', component: ListaUsuariosInternos, name: 'ListaUsuariosInternos', meta: { Administrador: true } },
    ],
  },
  {
    path: '/MainFacturacion', component: MainFacturacion, meta: { Administrador: true },
    children: [
      { path: 'ListFacturacion', component: ListFacturacion, name: 'ListFacturacion', meta: { Administrador: true } },
      { path: 'ListaClientesFacturacion', component: ListaClientes, name: 'ListaClientesFacturacion', meta: { Administrador: true } },
      { path: 'ListaConceptosFacturacion', component: ListaConceptos, name: 'ListaConceptosFacturacion', meta: { Administrador: true } },
    ],
  },
  {
    path: '/MainNotaCredito', component: MainNotaCredito, meta: { Administrador: true },
    children: [
      { path: 'ListNotaCredito', component: ListNotaCredito, name: 'ListNotaCredito', meta: { Administrador: true } },
      { path: 'ListaClientesNotaCredito', component: ListaClientes, name: 'ListaClientesNotaCredito', meta: { Administrador: true } },
      { path: 'ListaConceptosNotaCredito', component: ListaConceptos, name: 'ListaConceptosNotaCredito', meta: { Administrador: true } },
    ],
  },
  {
    path: '/MainIngresoCartaPorte', component: MainIngresoCartaPorte, meta: { Administrador: true },
    children: [
      { path: 'ListCartaPorte', component: ListCartaPorte, name: 'ListCartaPorte', meta: { Administrador: true } },
      { path: 'ListaConceptosCPI', component: ListaConceptos, name: 'ListaConceptosCPI', meta: { Administrador: true } },
      { path: 'ListaClientesCartaPorte', component: ListaClientes, name: 'ListaClientesCartaPorte', meta: { Administrador: true } },
      { path: 'ListaDomicilios', component: ListaDomicilios, name: 'ListaDomicilios', meta: { Administrador: true } },
      { path: 'ListaFigurasDeTransporte', component: ListaFigurasDeTransporte, name: 'ListaFigurasDeTransporte', meta: { Administrador: true } },
      { path: 'ListaIdentificacionVehicular', component: ListaIdentificacionVehicular, name: 'ListaIdentificacionVehicular', meta: { Administrador: true } },
      { path: 'ListaMercancias', component: ListaMercancias, name: 'ListaMercancias', meta: { Administrador: true } },
      { path: 'ListaRemolques', component: ListaRemolques, name: 'ListaRemolques', meta: { Administrador: true } },
      { path: 'ListaSeguros', component: ListaSeguros, name: 'ListaSeguros', meta: { Administrador: true } },
      { path: 'ListaUbicaciones', component: ListaUbicaciones, name: 'ListaUbicaciones', meta: { Administrador: true } },
    ],
  },
  {
    path: '/MainComercioExterior', component: MainComercioExterior, meta: { Administrador: true },
    children: [
      { path: 'ListComercioExterior', component: ListComercioExterior, name: 'ListComercioExterior', meta: { Administrador: true } },
      { path: 'ListaClientesComercioExterior', component: ListaClientes, name: 'ListaClientesComercioExterior', meta: { Administrador: true } },
      { path: 'ListaReceptor', component: ListaReceptor, name: 'ListaReceptor', meta: { Administrador: true } },
      { path: 'ListaDestinatarios', component: ListaDestinatarios, name: 'ListaDestinatarios', meta: { Administrador: true } },
      { path: 'ListaEmisor', component: ListaEmisor, name: 'ListaEmisor', meta: { Administrador: true } },
      { path: 'ListaPropietario', component: ListaPropietario, name: 'ListaPropietario', meta: { Administrador: true } },
      { path: 'ListaMercanciasCE', component: ListaMercanciasCE, name: 'ListaMercanciasCE', meta: { Administrador: true } },
    ]
  },
  {
    path: '/MainComplementoPago', component: MainComplementoPago, meta: { Administrador: true },
    children: [
      { path: 'ListaComplementoPago', component: ListaComplementoPago, name: 'ListaComplementoPago', meta: { Administrador: true } },
      { path: 'ListaClientesPagos', component: ListaClientes, name: 'ListaClientesPagos', meta: { Administrador: true } },
      { path: 'ListaConceptosPagos', component: ListaConceptos, name: 'ListaConceptosPagos', meta: { Administrador: true } },
    ]
  },
  {
    path: '/MainVentas', component: MainVentas, meta: { Administrador: true },
    children: [
      { path: 'ListaCotizacion', component: ListaCotizacion, name: 'ListaCotizacion', meta: { Administrador: true } },
      { path: 'ListaPedidos', component: ListaPedidos, name: 'ListaPedidos', meta: { Administrador: true } },
      { path: 'ListaProtocolos', component: ListaProtocolos, name: 'ListaProtocolos', meta: { Administrador: true } },
      { path: 'ListaRemision', component: ListaRemision, name: 'ListaRemision', meta: { Administrador: true } },
      { path: 'ListaConceptosV', component: ListaConceptos, name: 'ListaConceptosV', meta: { Administrador: true } },
      { path: 'ListaClientesV', component: ListaClientes, name: 'ListaClientesV', meta: { Administrador: true } },
      { path: 'ListaProyectos', component: ListaProyectos, name: 'ListaProyectos', meta: { Administrador: true } },
    ]
  },
  {
    path: '/MainCompras', component: MainCompras, meta: { Administrador: true },
    children: [
      { path: 'ListaPedidosC', component: ListaPedidosC, name: 'ListaPedidosC', meta: { Administrador: true } },
      { path: 'ListaCotizacionC', component: ListaCotizacionC, name: 'ListaCotizacionC', meta: { Administrador: true } },
      { path: 'ListaOrdenCompraC', component: ListaOrdenCompraC, name: 'ListaOrdenCompraC', meta: { Administrador: true } },
      { path: 'ListaProyectosC', component: ListaProyectos, name: 'ListaProyectosC', meta: { Administrador: true } },
      { path: 'ListaConceptosC', component: ListaConceptos, name: 'ListaConceptosC', meta: { Administrador: true } },
      { path: 'ListaProveedor', component: ListaProveedor, name: 'ListaProveedor', meta: { Administrador: true } },
    ]
  },
  {
    path: '/MainInventarios', component: MainInventarios, meta: { Administrador: true },
    children: [
      { path: 'ListAlmacenes', component: ListAlmacenes, name: 'ListAlmacenes', meta: { Administrador: true } },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.libre)) {
    next()
  } else if (store.state.usuario && store.state.usuario.rol == 'Administrador') {
    if (to.matched.some(record => record.meta.Administrador)) {
      next()
    }
  } else {
    next({
      name: 'Login'
    })
  }
})
export default router
