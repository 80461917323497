<template>
    <q-card>
        <q-toolbar>
            <q-btn push color="red-10" icon="mdi-close-circle-outline" round flat size="18px" padding="xs"
                @click="CierraDialog">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                    :offset="[10, 10]">Cerrar</q-tooltip>
            </q-btn>
            <q-toolbar-title><span class="text-weight-bold">Almacén</span></q-toolbar-title>
            <!-- BOTON PARA GUARDAR -->
            <q-btn v-if="almacen._id === ''" push color="green-10" icon="mdi-content-save" round flat size="18px"
                padding="xs" @click="PostAlmacen()">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                    :offset="[10, 10]">Guardar</q-tooltip>
            </q-btn>
            <q-btn v-else push color="green-10" icon="mdi-content-save" round flat size="18px" padding="xs"
                @click="PutAlmacen()">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                    :offset="[10, 10]">Editar</q-tooltip>
            </q-btn>
        </q-toolbar>
        <q-separator color="primary" inset size="4px" />
        <q-card-section class="q-pb-sm">
            <div class="row q-col-gutter-sm">
                <!-- DESCRIPCIÓN -->
                <div class="col-12">
                    <q-input outlined v-model="almacen.clave" label="Clave" dense />
                </div>
                <div class="col-12">
                    <q-input outlined v-model="almacen.descripcion" label="Descripción" dense />
                </div>
                <div class="col-12">
                    <q-input outlined v-model="almacen.direccion" label="Dirección" dense />
                </div>
                <div class="col-12">
                    <q-input outlined v-model="almacen.ligaMaps" label="Ubicación en Maps" dense />
                </div>
                <div class="col-12 col-md-6">
                    <q-input outlined v-model="almacen.telefono" label="Teléfono" dense mask="(###) ### - ####" />
                </div>
                <div class="col-12 col-md-6">
                    <q-input outlined v-model="almacen.correo" label="Correo" dense class="text-lowercase" />
                </div>
            </div>
        </q-card-section>
    </q-card>
</template>
<script>

import axios from 'axios'
import { QSpinnerCube } from 'quasar';
import moment from 'moment';

export default {
    components: {

    },

    data() {
        return {

        }
    },

    computed: {
        token() {
            return this.$store.state.usuario;
        },

        almacen() {
            return this.$store.state.almacenStore;
        },

    },

    created() {
    },

    methods: {
        CierraDialog() {
            this.$emit("Close");
        },

        async PostAlmacen() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'red-8', spinnerSize: 140, message: 'Guardando almacén...' });
            const valida = await this.Validacion();
            if (!valida) {
                return;
            }

            let objAlmacen = { ...this.$store.state.almacenStore }
            try {
                let response = await axios.post("Almacen/PostAlmacenAsync/" + this.token.rfc, objAlmacen);
                console.log(response.data);
                this.$q.loading.hide()
                this.$q.notify({
                    type: 'positive',
                    message: 'Almacén guardado con éxito',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                //ACTUALIZAMOS LA LISTA DE ALMACENES
                this.$emit("Actualiza");
                this.CierraDialog();
            } catch (error) {
                console.log(error);
                this.$q.loading.hide()
                this.$q.notify({
                    type: 'negative',
                    message: 'Error al guardar almacén, intente nuevamente',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
            }
        },

        async PutAlmacen() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'red-8', spinnerSize: 140, message: 'Actualizando almacén...' });
            const valida = await this.Validacion();
            if (!valida) {
                return;
            }

            let objAlmacen = { ...this.$store.state.almacenStore }
            try {
                let response = await axios.put("Almacen/PutAlmacenAsync/" + this.token.rfc, objAlmacen);
                console.log(response.data);
                this.$q.loading.hide()
                this.$q.notify({
                    type: 'positive',
                    message: 'Almacén actualizado con éxito',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                //ACTUALIZAMOS LA LISTA DE ALMACENES
                this.$emit("Actualiza");
                this.CierraDialog();
            } catch (error) {
                console.log(error);
                this.$q.loading.hide()
                this.$q.notify({
                    type: 'negative',
                    message: 'Error al guardar almacén, intente nuevamente',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
            }
        },

        async Validacion() {
            const objAlmacen = this.$store.state.almacenStore;
            if (!objAlmacen.clave) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Indique la clave del almacén',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return false;
            }

            if (!objAlmacen.descripcion) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Indique el nombre del almacén',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return false;
            }

            if (!objAlmacen.direccion) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Indique la dirección del almacén',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return false;
            }

            if (!objAlmacen.telefono) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Indique el teléfono del almacén',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return false;
            }

            if (!objAlmacen.correo) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Indique el correo del almacén',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return false;
            }

            return true;
        },
    },
}
</script>