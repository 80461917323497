<template>
    <div class="q-pa-md">
        <!-- DIALOG PARA UN NUEVO ALMACEN -->
        <q-dialog v-model="dialogAlmacen" persistent transition-show="slide-up" transition-hide="slide-down">
            <almacen @Close="dialogAlmacen = false" @Actualiza="GetAlmacenes()"></almacen>
        </q-dialog>
        <!-- SELECCIONA AÑO Y MES, BOTON DE BUSCAR Y EXPORTAR A EXCEL -->
        <div class="row no-wrap items-center q-mt-md q-pa-sm">
            <div class="text-h5">Almacén</div>
            <q-space />
            <q-btn push color="amber-9" icon="mdi-text-box-search-outline" rounded flat size="18px" padding="xs"
                @click="GetAlmacenes()">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                    :offset="[10, 10]">Consultar</q-tooltip>
            </q-btn>
            <q-btn push color="green-14" @click="NuevoAlmacen()" icon="mdi-plus-circle" rounded flat size="18px"
                padding="xs">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                    :offset="[10, 10]">Nuevo Almacén</q-tooltip>
            </q-btn>
        </div>
        <!-- TABLA DE ALMACENES -->
        <q-table title="Almacenes" :data="dataAlmacen" :columns="columns" row-key="_id" :rows-per-page-options="[10]"
            :filter="filter" class="my-sticky-column-table shadow-0" dense>
            <template v-slot:top>
                <q-space />
                <q-input borderless dense debounce="300" v-model="filter" placeholder="Filtrar">
                    <template v-slot:append>
                        <q-icon name="mdi-magnify" />
                    </template>
                </q-input>
            </template>
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td auto-width>
                        <q-btn v-if="props.row.estatus != 'Facturado'" size="md" color="red" rounded flat dense
                            @click="eliminar(props.row)" icon="mdi-delete">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px">Eliminar</q-tooltip>
                        </q-btn>
                        <q-btn size="md" color="blue" rounded flat dense @click="EditaAlmacen(props.row)"
                            icon="mdi-pencil">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px" :offset="[10, 10]">Editar</q-tooltip>
                        </q-btn>
                    </q-td>
                    <q-td key="clave" :props="props">{{ props.row.clave }}</q-td>
                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                    <q-td key="telefono" :props="props">{{ props.row.telefono }}</q-td>
                    <q-td key="correo" :props="props">{{ props.row.correo }}</q-td>
                </q-tr>
            </template>
        </q-table>
    </div>
</template>
<script>
import axios from 'axios'
import * as XLSX from 'xlsx';
import { QSpinnerCube } from 'quasar';
import almacen from './Almacen.vue'

export default {
    components: {
        almacen,
    },
    data() {
        return {
            filter: '',
            dataAlmacen: [],
            columns: [
                { name: 'actions', align: 'left', label: 'Acciones', field: 'actions' },
                { name: 'clave', align: 'left', label: 'Clave', field: 'clave', sortable: true },
                { name: 'descripcion', align: 'left', label: 'Descripción', field: 'descripcion', sortable: true },
                { name: 'telefono', align: 'left', label: 'Teléfono', field: 'telefono', sortable: true },
                { name: 'correo', align: 'left', label: 'Correo', field: 'correo', sortable: true },
            ],

            dialogAlmacen: false,
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
    },

    created() {
        this.GetAlmacenes();
    },

    methods: {
        async GetAlmacenes() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'red-8', spinnerSize: 140, message: 'Consultando almacenes...' });
            try {
                let response = await axios.get("Almacen/GetAlmacenAsync/" + this.token.rfc);
                const x = [...response.data];
                this.dataAlmacen = [...x]
                this.$q.loading.hide()
            } catch (error) {
                console.log(error);
                this.$q.loading.hide()
            }
        },

        NuevoAlmacen() {
            const ObjAlmacen = {
                _id: "",
                clave: null,
                descripcion: null,
                direccion: null,
                ligaMaps: null,
                telefono: null,
                correo: null,
                usuarios: [],
            };
            this.$store.state.almacenStore = { ...ObjAlmacen }
            this.dialogAlmacen = true;
        },

        EditaAlmacen(item) {
            const ObjAlmacen = { ...item }
            this.$store.state.almacenStore = { ...ObjAlmacen }
            this.dialogAlmacen = true;
        },

        FormatDate(value) {
            let fecha = new Date(value);
            let dia = fecha.getDate();
            let mes = fecha.toLocaleString('es-ES', { month: 'long' }); // Obtener el mes en formato de letra
            let anio = fecha.getFullYear();
            let formatoDeseado = `${dia}-${mes}-${anio}`;
            return formatoDeseado;
        },

        FormatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
    },
}
</script>
<style scoped>
.Nuevo {
    background-color: #2196f3;
    padding: 10px;
    font-size: 15px
}

.Facturado {
    background-color: #9c27b0;
    padding: 10px
}

.Cancelado {
    background-color: #f44336;
    padding: 10px
}

.Autorizado {
    background-color: #4caf50;
    padding: 10px
}
</style>
<style lang="sass">
    .my-sticky-column-table
    
      thead tr:first-child th:first-child
        background-color: #ffffff
    
      td:first-child
        background-color: #ffffff
    
      th:first-child,
      td:first-child
        position: sticky
        left: 0
        z-index: 1
    </style>