import { render, staticRenderFns } from "./MainInventarios.vue?vue&type=template&id=7cf8a26e"
import script from "./MainInventarios.vue?vue&type=script&lang=js"
export * from "./MainInventarios.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QFooter from 'quasar/src/components/footer/QFooter.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QLayout,QHeader,QToolbar,QBtn,QToolbarTitle,QDrawer,QScrollArea,QList,QItem,QItemSection,QIcon,QSeparator,QPageContainer,QFooter});qInstall(component, 'directives', {Ripple});
